/* ---- reset ---- */

body {
	margin: 0;
	background-color: #0d0e1a;
}

canvas {
	display: block;
	vertical-align: bottom;
}

/* FONTS */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@font-face {
	font-family: SpaceCats;
	src: url(../fonts/main.ttf);
}

h1 {
	font-family: SpaceCats;
	color: white;
}

img {
	height: 22vh; /* 28vh */
	margin: auto;
	vertical-align: middle;
	border-style: none;
}

/* MAIN SOURCE */

.col-md-3 {
	margin-top: 15px;
	float: none;
	display: inline-block;
	width: 300px;
}

.tk {
	margin-top: 15px;
	float: none;
	display: inline-block;
	width: 300px;
}

.splash-card {
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	height: 100%;
	border: 3px solid transparent;
	box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
	overflow: hidden;
}

.splash-card[data-rarity="common"] {
	background: radial-gradient(#b7b7b7, #656565);
	border-color: #b7b7b7;
}

.splash-card[data-rarity="uncommon"] {
	background: radial-gradient(#8fff62, #1b511c);
	border-color: #8fff62;
}

.splash-card[data-rarity="rare"] {
	background: radial-gradient(#52b6ff, #183c76);
	border-color: #52b6ff;
}

.splash-card[data-rarity="epic"] {
	background: radial-gradient(#c36aff, #4a2981);
	border-color: #c36aff;
}

.splash-card[data-rarity="legendary"] {
	background: radial-gradient(#ff8b51, #773821);
	border-color: #ff8b51;
}

.card {
	background-color: #343434;
	cursor: pointer;
}

.shop-rotation .splash-card {
	margin-bottom: 15px;
}

.card-img-overlay {
	padding: 0;
}

.card-body {
	padding: 0.5rem;
	background-color: #222;
}

.itemdesc-box {
	background: rgba(0, 0, 0, 0.3);
	position: absolute;
	width: 100%;
	bottom: 0;
}

.itemname {
	font-size: 40px !important;
	text-align: center;
	text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	margin: 4px;
	margin-bottom: 0;
}

.splash-card.selected img.card-img-top,
.splash-card:not(.static):hover img.card-img-top {
	-webkit-transform: scale3d(1.05, 1.05, 1);
	transform: scale3d(1.05, 1.05, 1);
}

.activeCard {
	border-color: #ff0 !important;
	border-width: 5px;
}

.splash-card::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.15);
	opacity: 0;
}

.splash-card:hover::before {
	opacity: 1;
}

.winning {
	font-size: 2.45em;
	margin: 0.2em 0;

	border: solid 1px grey;
	user-select: none;

	color: transparent;
}

.custom-social-proof {
	position: fixed;
	bottom: 20px;
	left: 20px;
	z-index: 9999999999999 !important;
	display: none;
}
.custom-notification {
	width: 300px;
	border: 0;
	text-align: left;
	z-index: 99999;
	box-sizing: border-box;
	font-weight: 400;
	border-radius: 6px;
	box-shadow: 2px 2px 10px 2px hsla(0, 4%, 4%, 0.2);
	background-color: #fff;
	position: relative;
	cursor: pointer;
}
.custom-close {
	position: absolute;
	top: 8px;
	right: 8px;
	height: 12px;
	width: 12px;
	cursor: pointer;
	transition: 0.2s ease-in-out;
	transform: rotate(45deg);
	opacity: 0;
}
.custom-close::before {
	content: "";
	display: block;
	width: 100%;
	height: 2px;
	background-color: gray;
	position: absolute;
	left: 0;
	top: 5px;
}
.custom-close::after {
	content: "";
	display: block;
	height: 100%;
	width: 2px;
	background-color: gray;
	position: absolute;
	left: 5px;
	top: 0;
}
.custom-close:hover {
	opacity: 1;
}

.custom-notification-image-wrapper img {
	max-height: 85px;
	max-width: 85px;
	overflow: hidden;
	padding: 0 15px 0 15px;
	border-radius: 6px 0 0 6px;
}

.custom-notification-container {
	display: flex !important;
	align-items: center;
	height: 80px;
}

.custom-notification-content-wrapper {
	margin: 0;
	height: 100%;
	color: gray;
	padding-left: 6px;
	padding-right: 20px;
	border-radius: 0 6px 6px 0;
	flex: 1;
	display: flex !important;
	flex-direction: column;
	justify-content: center;
}

.custom-notification-content {
	font-family: inherit !important;
	margin: 0 !important;
	padding: 0 !important;
	font-size: 14px;
	line-height: 19px;
}

small {
	margin-top: 3px !important;
	display: block !important;
	font-size: 12px !important;
	opacity: 0.8;
}

#winner_name {
	font-weight: 600;
	color: #000;
}

.counterwrapper {
	padding: 1rem;
	margin: 1rem;
}

.disks-content h3 {
	color: #e88df3;
}

.disks-content h1 {
	line-height: normal;
	margin-bottom: 5px;
}

.disks-content a {
	margin-top: 20px !important;
}

.disks-section {
	-webkit-animation: textAnimateIn 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
		0.3s forwards;
	animation: textAnimateIn 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s
		forwards;
	opacity: 0;
}

@media all and (max-width: 420px) and (min-height: 320px) {
	.logo {
		width: 100%;
		margin-top: 20px;
	}
	.holderInput {
		width: 100%;
	}
	.claim,
	.verify {
		width: 70% !important;
	}
	.disks-content h1 {
		margin-top: 10px;
	}
	.col-md-3 {
		width: 250px;
		height: 220px;
	}
}

.timer {
	display: none;
	position: relative;
	padding: 0.6rem 0 0.1rem 1rem;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	text-align: left;
	top: 0;
	right: 10px;
	font-size: 2rem;
	color: #fff;
}

.timer span {
	position: relative;
	display: inline-block;
}

.verifying {
	padding: 40px;
	margin: auto;
	border-radius: 10px;
	border: 2px solid #fff;
}

.status {
	font-size: 29px;
}

.status span {
	font-weight: 700;
}

.successText {
	color: #43d133;
}

.errorText {
	color: #d13333;
}

.yellowText {
	color: #ff0;
}

.iconSeries {
	position: absolute;
	width: 70px;
	z-index: 1;
	left: 22px;
	top: -2px;
}

.items {
	width: 90vw;
	max-height: 41vh;
	margin: auto;
	margin-bottom: 15px;
	overflow-y: auto;
}

.card-img-top {
	height: 100%;
	transition: 0.2s;
}

.card {
	border: none;
}

.holderInput {
	position: relative;
	display: table;
	border-collapse: separate;
	width: 450px;
	margin: auto;
	margin-bottom: 15px;
}

input {
	margin: auto;
	border-radius: 10px;
	background: transparent;
	border: 2px solid #fff;
	text-align: center;
	color: #fff;
	font-family: "Open Sans", sans-serif;
	font-size: 34px;
	padding: 8px;
	width: 80%;
	outline: none;
	text-transform: none;
	display: block;
	margin-bottom: 20px;
}

input::placeholder {
	font-family: SpaceCats;
	font-size: 38px;
}

.button {
	background: transparent;
	color: rgba(255, 255, 255, 0.7);
	border: 2px solid #fff;
	border-radius: 10px;
	width: auto;
	font-size: 25px;
	font-family: "Open Sans", sans-serif;
	outline: none !important;
	padding: 8px;
	cursor: pointer;
	display: block;
	margin: auto;
	margin-bottom: 50px;
}

.button:hover {
	color: #fff;
}

.claim[disabled] {
	cursor: not-allowed;
	color: rgba(255, 255, 255, 0.7);
}

.activeCard {
	border: 5px solid #ff0;
}

.verifying {
	display: none;
}

.verify {
	width: 200px;
	display: none;
}

.main {
	color: #fff;
}

.container {
	width: 70%;
	margin: auto;
	margin-bottom: 2rem;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 9px;
	border: 2px solid;
	padding: 20px;
	overflow: scroll;
}

.stats {
	display: flex;
	flex-grow: 1;

	justify-content: center;
	gap: 0.75em;
	align-items: center;
	flex-direction: row;
	padding: 1.25rem;
	width: 100%;
}

.stat {
	font-size: 1.5rem;
	line-height: 2rem;
	justify-content: center;
	width: 23.5%;
	border-radius: 10px;
	border: solid;
	height: 160px;
}

.box {
	display: flex;
	padding: 10px;
    margin: -10px
}


@media (min-width: 640px) {
	.container {
		max-width: 640px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 768px;
	}
}

@media (min-width: 1024px) {
	.container {
		margin-bottom: 3rem;

		max-width: 1024px;
	}
}

.col > a {
	font-family: SpaceCats;
	font-size: 30px;
}

.walletBox {
	padding: 15px;
}

.walletBox > a {
	font-family: SpaceCats;
	font-size: 35px;
}

.statusBox {
	font-family: SpaceCats;
	padding: 10px;
	line-height: 1;
}

.balanceBox {
	padding: 10px;
}

.balanceBox > a {
	font-family: SpaceCats;
	font-size: 35px;
}
.subtext {
	font-size: 25px !important;
	color: #888888 !important;
	margin-top: 6px;
}

.claimBtn {
	margin-top: 10px !important;
	font-size: 25px !important;
}

.connectBtn {
	position: relative;
	background: transparent;
	outline: none;
	border-radius: 10px;
	color: rgb(255, 255, 255);
	font-family: SpaceCats;
	border: 2px solid rgb(255, 255, 255);
	padding: 0.5rem;
	letter-spacing: 2px;
	font-size: 1.5em;
	margin-top: 1vw;
}

.connect {
	float: right;
	margin-right: -22px;
	background-color: #3966e4;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.5rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	margin-bottom: 2rem;
}

.container > div {
	margin: auto;
}

.inner {
	margin: auto;
	align-items: center;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.inner > h2 {
	text-align: center;
	margin-bottom: 3rem;
	font-size: 1.875rem;
	line-height: 2.25rem;
	font-weight: 700;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
	margin-top: 0;
}

@media (min-width: 768px) {
	.inner > h2 {
		font-size: 3rem;
		line-height: 1;
	}
}

@media (min-width: 1024px) {
	.inner > h2 {
		font-size: 4.5rem;
		line-height: 1;
		margin-bottom: 5rem;
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;
}

@media (min-width: 768px) {
	.content {
		flex-direction: row;
		gap: 5rem;
	}
}

.sideContainer {
	padding: 20px;
	border: 2px solid #fff;
	border-radius: 10px;
}

@media (min-width: 768px) {
	.sideContainer {
		width: 24rem;
	}
}

.holder > div {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;
	list-style: none;
	padding: 0;
	z-index: 1;
	touch-action: pan-y;
}

.holder > div {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	transition-property: transform;
	box-sizing: content-box;
	transform: translateZ(0);
	transition-duration: 0ms;
}

.holder > div > div {
	flex-shrink: 0;
	height: 100%;
	position: relative;
	background-color: #000;
	width: 336px;
	transition-duration: 0ms;
	opacity: 1;
	transform: translate3d(0px, 0px, 0px);
	pointer-events: none;
	transition-property: opacity;
}

img {
	max-width: 100%;
	height: auto;
	border-style: none;
}

.nft {
	width: 336px;
	border-radius: 4px;
	box-shadow: 0 0 14px 0 #000;
}

.infoContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media (min-width: 768px) {
	.infoContainer {
		align-items: flex-start;
	}
}

.infoContainer > h1 {
	padding-top: 1rem;
	padding-bottom: 1rem;
	font-size: 2.5rem;
	line-height: 2.25rem;
	font-weight: 500;
	margin: auto;
}

.progressData {
	display: flex;
	width: 24rem;
	flex-direction: column;
	align-items: center;
}

@media (min-width: 768px) {
	.progressData {
		align-items: flex-start;
	}
}

.progressText {
	letter-spacing: 2px;
	display: inline-block;
}

.progressBar {
	width: 100%;
	margin-top: 0.5rem;
	height: inherit;
}

.progressBar > div {
	position: relative;
	display: block;
	max-width: 100%;
	box-shadow: none;
	padding: 0;
	font-size: 0.92857143rem;
	background: rgba(255, 255, 255, 0.08);
	border: 4px solid #aaa;
	margin: 0 0 1.5em;
}

.bar {
	display: block;
	line-height: 1;
	position: relative;
	width: 0;
	height: 2em;
	min-width: 2em;
	background-color: #2ecc40;
	border: 2.5px solid #000;
	transition: width 0.1s ease, background-color 0.1s ease;
}

.cart {
	margin: 1rem auto;
}

.amountText {
	text-align: center;
	font-size: 1.125rem;
	line-height: 1.75rem;
	margin-bottom: 1rem;
}

.amount {
	display: flex;
	margin: auto;
	width: 8rem;
	justify-content: center;
	font-size: 1.875rem;
	line-height: 2.25rem;
	font-weight: 700;
}

/* @media (min-width: 768px) {
    .amount {
        align-items: flex-start;
        justify-content: flex-start;
    }
} */

.amount > button {
	color: inherit;
	background: transparent;
	border: none;
	cursor: pointer;
	font-weight: 700;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	padding: 0;
	outline: none;
}

.amount > button:hover {
	color: #2ecc40;
}

.itemAmount {
	width: 3.5rem;
	text-align: center;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.buy {
	cursor: pointer;
	background: transparent;
	outline: none;
	border-radius: 10px;
	color: #fff;
	font-family: "SpaceCats";
	border: 2px solid #fff;
	padding: 0.5rem 5rem;
	font-size: 2.5em;
	margin: 10px auto;
}

footer {
	padding: 50px;
}

footer > h2 {
	color: #fff;
	font-weight: 700;
	font-size: 1.875rem;
	line-height: 2.25rem;
	margin-bottom: 2rem;
}

footer > p {
	margin: 0 0 1em;
	line-height: 1.4285em;
	font-size: 1.125rem;
}

@media (min-width: 1024px) {
	footer > p {
		margin-bottom: 2rem;
	}
}

footer > button {
	color: #fff;
	background: #23272a;
	border-radius: 28px;
	font-size: 20px;
	padding: 16px 32px;
	outline: none;
	border: none;
	margin-top: 20px;
	cursor: pointer;
	transition: 0.2s ease-in-out;
}

footer > button:hover {
	background-color: #36393f;
	box-shadow: 0 8px 15px rgb(0 0 0 / 20%);
}

footer > button:focus {
	outline: none;
}

/* custom scrollbar */
::-webkit-scrollbar {
	width: 20px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #d6dee1;
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #a8bbbf;
}
.col {
	width: 167px;
	height: 280px;
	margin: 0 4px;
	border-radius: 4px;
	padding: 9px;
	flex: none !important;
	margin-right: 10px !important;
/* 
	box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #13fe9a,
		0 0 0.8rem #bc13fe, 0 0 2.8rem #bc13fe, inset 0 0 1.3rem #bc13fe; */
}

.claimBtn {
	margin-top: 6px;
}

.customBtn {
	cursor: pointer;
	background: transparent;
	outline: none;
	border-radius: 10px;
	color: rgb(255, 255, 255);
	font-family: SpaceCats;
	border: 2px solid rgb(255, 255, 255);
	padding: 0.5rem;
	letter-spacing: 4px;
	font-size: 1.5em;
	width: 69%;
}

.customBtn:hover {
	background: #ffffff91;
}

a {
	color: white;
	text-decoration: none;
}

a:hover {
	color: white;
	text-decoration: none;
}

.route {
	color: white;
	text-decoration: none;
	transform: scale(1);
	display: inline-block;
	transition: all 0.2s ease-in-out;
}

.route:hover {
	color: #ddd;
	text-decoration: none;
	transform: scale(1.2);
}

.team-member {
	display: inline-flex;
	flex-direction: column;
	gap: 1vw;
	align-items: center;
	width: 20%;
}

.team-member img {
	box-shadow: 0 0 17px 3px red, 0 0 4px 2px red;
}

section#timeline {
	width: 80%;
	margin: 20px auto;
	position: relative;
}
section#timeline:before {
	content: "";
	display: block;
	position: absolute;
	left: 50%;
	top: 0;
	margin: 0 0 0 -1px;
	width: 2px;
	height: 70%;
	background: rgba(255, 255, 255, 0.2);
}
section#timeline article {
	width: 100%;
	margin: 0 0 20px 0;
	position: relative;
}
section#timeline article:after {
	content: "";
	display: block;
	clear: both;
}
section#timeline article div.inner {
	width: 40%;
	float: left;
	margin: 5px 0 0 0;
	border-radius: 6px;
}
section#timeline article div.inner span.date {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 60px;
	height: 50px;
	padding: 5px 0;
	position: absolute;
	top: 0;
	left: 50%;
	margin: 0 0 0 -32px;
	border-radius: 100%;
	font-size: 12px;
	font-weight: 900;
	text-transform: uppercase;
	background: #25303b;
	color: white;
	border: 2px solid rgba(255, 255, 255, 0.2);
	box-sizing: initial;
	-webkit-box-sizing: initial;
}
section#timeline article div.inner span.date span {
	display: block;
	text-align: center;
}

section#timeline article div.inner span.date span.year {
	font-size: 18px;
}
section#timeline article div.inner h2 {
	padding: 15px;
	margin: 0;
	color: #fff;
	font-size: 20px;
	text-transform: uppercase;
	letter-spacing: -1px;
	border-radius: 6px 6px 0 0;
	position: relative;
}
section#timeline article div.inner h2:after {
	content: "";
	position: absolute;
	top: 20px;
	right: -5px;
	width: 10px;
	height: 10px;
	-webkit-transform: rotate(-45deg);
}
section#timeline article div.inner p {
	padding: 5px;
	margin: 0;
	font-size: 20px;
	background: #1a212c;
	color: white;
	border-radius: 0 0 6px 6px;
}
section#timeline article:nth-child(2n + 2) div.inner {
	float: right;
}
section#timeline article:nth-child(2n + 2) div.inner h2:after {
	left: -5px;
}

section#timeline article div.inner h2 {
	background: #25303b;
}
section#timeline article div.inner h2:after {
	background: #25303b;
}

section#timeline ul {
	text-align: left;
	/* padding: 0; */
}

section#timeline ul li::marker {
	font-weight: bold;
	font-size: 1.5em;
}

section#timeline ul li.completed::marker {
	content: "✓ ";
	color: green;
	vertical-align: middle;
}

.accordion .accordion-item {
	border-bottom: 1px solid #e5e5e5;
}
.accordion .accordion-item button[aria-expanded="true"] {
	border-bottom: 1px solid #999;
}
.accordion button {
	position: relative;
	display: block;
	text-align: left;
	width: 100%;
	padding: 1em 0;
	color: white;
	font-size: 1.15rem;
	font-weight: 400;
	border: none;
	background: none;
	outline: none;
	transition: all 200ms linear;
}
.accordion button:hover,
.accordion button:focus {
	cursor: pointer;
	color: #999;
}
.accordion button:hover::after,
.accordion button:focus::after {
	cursor: pointer;
	color: #999;
	border: 1px solid #999;
}
.accordion button .accordion-title {
	padding: 1em 1.5em 1em 0;
}
.accordion button .icon {
	display: inline-block;
	position: absolute;
	top: 18px;
	right: 0;
	width: 22px;
	height: 22px;
	border: 1px solid;
	border-radius: 22px;
}
.accordion button .icon::before {
	display: block;
	position: absolute;
	content: "";
	top: 9px;
	left: 5px;
	width: 10px;
	height: 2px;
	background: currentColor;
}
.accordion button .icon::after {
	display: block;
	position: absolute;
	content: "";
	top: 5px;
	left: 9px;
	width: 2px;
	height: 10px;
	background: currentColor;
}
.accordion button[aria-expanded="true"] {
	color: #999;
}
.accordion button[aria-expanded="true"] .icon::after {
	width: 0;
}
.accordion button[aria-expanded="true"] + .accordion-content {
	opacity: 1;
	max-height: fit-content;
	transition: all 200ms linear;
	will-change: opacity, max-height;
}
.accordion .accordion-content {
	opacity: 0;
	max-height: 0;
	overflow: hidden;
	transition: opacity 200ms linear, max-height 200ms linear;
	will-change: opacity, max-height;
}
.accordion .accordion-content p {
	font-size: 1rem;
	font-weight: 300;
	margin: 2em 0;
	text-align: left;
}

.post-button {
	border-radius: 6px;
	padding: 30px;
	display: inline-block;
	border: solid;
	margin: 10px;
	width: 50%;
}

.voting-status {
	left: 0px;
	top: 0px;
	position: absolute;
	border: none;
	outline: none;
	background: red;
	border-radius: 10%;
	color: white;
	padding: 5px 10px;
	margin: 5px;
	pointer-events: none;
	font-weight: bold;
}

.voting-status.active {
	background: #58ca55;
}

@media only screen and (max-width: 956px) {
	.stats {
		flex-direction: column;
	}

	.stat {
		width: 70%;
	}

	.column-text-container {
		flex-direction: column-reverse;
		align-items: center;
	}

	.column-text-container img {
		margin-bottom: 15% !important;
	}

	.team-member {
		display: flex;
		width: auto;
	}

	.team-member img {
		width: 18vw !important;
		height: 18vw !important;
	}

	section#timeline:before {
		height: 70%;
	}

	.voting-status {
		position: relative;
		margin: 0;
		width: 100%;
		border-radius: 0;
	}
}
